import React from "react"
import { PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/HomePage/Header"
import HorizontalGallery from "../components/Common/HorizontalGallery"

const IndexPage = (props: PageProps) => (
  <Layout>
    <SEO title=" " />
    <Header />
    <HorizontalGallery />
  </Layout>
)

export default IndexPage

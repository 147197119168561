import React from "react"
import "./style.css"

const logo = require("../../../images/logo.png")
const backgroundImage = require("../../../images/header-image.jpg")

export default () => {
  return (
    <section
      className="home-page-header"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <img src={logo} width={400} />
      <h1>
        Lyckligt, gott <br /> och närproducerat
      </h1>
    </section>
  )
}

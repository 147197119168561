import React from "react"
import "./style.css"

const imageOne = require("../../../images/bee_new.png")
const imageTwo = require("../../../images/sheep_new.png")
const imageThree = require("../../../images/skin_new.png")
const imageFour = require("../../../images/pig_new.png")

export default () => {
  return (
    <section className="common horizontal-gallery">
      <ul>
        <li>
          <img src={imageOne} />
        </li>
        <li>
          <img src={imageTwo} />
        </li>
        <li>
          <img src={imageThree} />
        </li>
        <li>
          <img src={imageFour} />
        </li>
      </ul>
    </section>
  )
}
